"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateGroupSettings = void 0;
const client_1 = require("@apollo/client");
const updateGroupSettings_1 = require("../mutations/updateGroupSettings");
const useUpdateGroupSettings = (groupId) => {
    const [update, { loading, called }] = (0, client_1.useMutation)(updateGroupSettings_1.UPDATE_GROUP_SETTINGS);
    const updateGroupSettings = (data) => __awaiter(void 0, void 0, void 0, function* () {
        return yield update({
            variables: {
                groupId,
                data,
            },
            optimisticResponse: {
                updateGroupSettings: {
                    id: groupId,
                    //@ts-ignore
                    __typename: "GroupEntity",
                    availabilityEndDate: data.availabilityEndDate,
                    startAt: data.startAt,
                    endAt: data.endAt,
                    hasRepository: data.hasRepository || false,
                    repositoryPrice: data.repositoryPrice,
                    repositoryFilePath: data.repositoryFilePath,
                },
            },
            update: (cache, { data }) => {
                if (data === null || data === void 0 ? void 0 : data.updateGroupSettings) {
                    updateGroupInCache(cache, data.updateGroupSettings);
                }
            },
        });
    });
    const updateGroupInCache = (cache, data) => {
        const groupSettingsToUpdate = {
            id: groupId,
            __typename: "GroupSettingsEntity",
        };
        cache.modify({
            id: cache.identify(groupSettingsToUpdate),
            fields: {
                availabilityEndDate(prev) {
                    return data.availabilityEndDate || prev;
                },
                startAt(prev) {
                    return data.startAt || prev;
                },
                endAt(prev) {
                    return data.endAt || prev;
                },
                hasRepository(prev) {
                    return typeof data.hasRepository !== "undefined"
                        ? data.hasRepository
                        : prev;
                },
                repositoryPrice(prev) {
                    return data.repositoryPrice !== undefined
                        ? data.repositoryPrice
                        : prev;
                },
                repositoryFilePath(prev) {
                    return data.repositoryFilePath || prev;
                },
            },
        });
    };
    return { updateGroupSettings, loading, called };
};
exports.useUpdateGroupSettings = useUpdateGroupSettings;
