"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SYNC_REPOSITORY_ACCESS_BY_CHECKOUT_ID = void 0;
const client_1 = require("@apollo/client");
exports.SYNC_REPOSITORY_ACCESS_BY_CHECKOUT_ID = (0, client_1.gql) `
  mutation SyncRepositoryAccessByCheckoutId($checkoutId: String!) {
    syncRepositoryAccessByCheckoutId(checkoutId: $checkoutId) {
      id
      status
      hasRepositoryAccess
      user {
        id
        email
      }
      group {
        id
        name
      }
    }
  }
`;
