"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_GROUP_SETTINGS = void 0;
const client_1 = require("@apollo/client");
exports.FIND_GROUP_SETTINGS = (0, client_1.gql) `
  query FindGroupSettings($groupId: String!) {
    findGroupSettings(groupId: $groupId) {
      id
      endAt
      startAt
      groupId
      availabilityEndDate
      allowAutoAccess
      allowDisplayCalendar
      allowDisplaySeats
      allowNewEntries
      allowProgramPreview
      allowTryForFree
      mentorCalendarUrl
      previewGroupId
      isPrimaryGroup
      viewInMarketplace
      webhookUrl
      hasRepository
      repositoryPrice
      repositoryFilePath
    }
  }
`;
