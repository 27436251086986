"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapGroupUpsellEntity = exports.mapGroupEntityToGroupProgram = exports.mapGroupPageEntityToGroupPageData = void 0;
const mapGroupPageEntityToGroupPageData = (page, notionPage) => {
    var _a, _b, _c, _d, _e, _f;
    if (!page.group) {
        throw Error("Group relation not included");
    }
    if (!((_a = page.group) === null || _a === void 0 ? void 0 : _a.settings)) {
        throw Error("Group settings not included");
    }
    if (!page.group.program) {
        throw Error("Program relation not included");
    }
    if (!page.document) {
        throw Error("Document relation not included");
    }
    return {
        id: page.id,
        currency: page.group.currency,
        notionPageMap: notionPage || null,
        notionPageId: ((_b = page.document) === null || _b === void 0 ? void 0 : _b.notionPageId) || null,
        document: ((_c = page.document) === null || _c === void 0 ? void 0 : _c.content) || null,
        documentId: page.document.id,
        benefits: page.benefits,
        paymentMode: page.group.paymentMode,
        showPrice: page.showPrice,
        ctaButtonType: page.ctaButtonType,
        groupId: page.group.id,
        groupName: page.group.name,
        groupState: page.group.state,
        contactListId: (page === null || page === void 0 ? void 0 : page.contactListId) || null,
        gtmId: (page === null || page === void 0 ? void 0 : page.gtmId) || null,
        programId: page.group.program.id,
        maxSeats: page.group.settings.maxSeats,
        coverImageSrc: ((_d = page.coverImage) === null || _d === void 0 ? void 0 : _d.url) || null,
        coverMediaSrc: page.coverMediaSrc || null,
        coverType: page.coverType,
        termsAndConditionsUrl: (page === null || page === void 0 ? void 0 : page.termsAndConditionsUrl) || "",
        privacyPolicyUrl: (page === null || page === void 0 ? void 0 : page.privacyPolicyUrl) || "",
        usersAmount: ((_f = (_e = page.group) === null || _e === void 0 ? void 0 : _e.accesses) === null || _f === void 0 ? void 0 : _f.length) || 0,
        tags: page.group.tags.map((tag) => tag.name),
        allowNewEntries: page.group.settings.allowNewEntries,
        allowDisplaySeats: page.group.settings.allowDisplaySeats,
        allowAutoAccess: page.group.settings.allowAutoAccess,
        allowProgramPreview: page.group.settings.allowProgramPreview,
        allowTryForFree: page.group.settings.allowTryForFree,
        allowDisplayCalendar: page.group.settings.allowDisplayCalendar,
        previewGroupId: page.group.settings.previewGroupId || "",
        mentorCalendarUrl: page.group.settings.mentorCalendarUrl || "",
        pricePerSeat: page.group.pricePerSeat,
        shortDsc: page.shortDsc,
        slug: page.slug,
        state: page.state,
        hasRepository: page.group.settings.hasRepository || false,
        repositoryPrice: page.group.settings.repositoryPrice || null,
        repositoryFilePath: page.group.settings.repositoryFilePath || null,
    };
};
exports.mapGroupPageEntityToGroupPageData = mapGroupPageEntityToGroupPageData;
const mapGroupEntityToGroupProgram = (group) => {
    var _a, _b, _c;
    if (!group.tags) {
        throw Error("Tags relation not included");
    }
    if (!group.page) {
        throw Error("Page relation not included");
    }
    return {
        coverImageSrc: ((_a = group.page.coverImage) === null || _a === void 0 ? void 0 : _a.url) || null,
        name: group.name,
        startAt: ((_b = group.settings) === null || _b === void 0 ? void 0 : _b.startAt) || null,
        endAt: ((_c = group.settings) === null || _c === void 0 ? void 0 : _c.endAt) || null,
        pricePerSeat: group.pricePerSeat,
        currency: group.currency,
        paymentMode: group.paymentMode,
        showPrice: group.page.showPrice,
        shortDsc: group.page.shortDsc,
        slug: group.page.slug,
        state: group.state,
        tags: group.tags.map((tag) => tag.name),
    };
};
exports.mapGroupEntityToGroupProgram = mapGroupEntityToGroupProgram;
const mapGroupUpsellEntity = (data) => {
    var _a, _b, _c, _d, _e, _f;
    if (!((_b = (_a = data.group) === null || _a === void 0 ? void 0 : _a.page) === null || _b === void 0 ? void 0 : _b.slug)) {
        throw Error("Upsold group does not have page generated");
    }
    if (!data.groupId || !data.group.tags || !data.group.name) {
        throw Error("One of params in GroupUpsellEntity is missing");
    }
    return {
        endDate: ((_c = data.group.settings) === null || _c === void 0 ? void 0 : _c.endAt) || null,
        groupId: data.groupId,
        isSubscription: data.group.paymentMode === "SUBSCRIPTION",
        currency: data.group.currency,
        pricePerSeat: data.group.pricePerSeat,
        highlighted: data.highlighted,
        coverImageSrc: ((_e = (_d = data.group.page) === null || _d === void 0 ? void 0 : _d.coverImage) === null || _e === void 0 ? void 0 : _e.url) || null,
        shortDsc: ((_f = data.group.page) === null || _f === void 0 ? void 0 : _f.shortDsc) || "",
        id: data.id,
        name: data.group.name,
        slug: data.group.page.slug,
        tags: data.group.tags.map((tag) => tag.name),
    };
};
exports.mapGroupUpsellEntity = mapGroupUpsellEntity;
