"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetRepositoryFileUrl = void 0;
const client_1 = require("@apollo/client");
const getRepositoryFileUrl_1 = require("../queries/getRepositoryFileUrl");
const useGetRepositoryFileUrl = (groupId) => {
    const { data, loading, error, refetch } = (0, client_1.useQuery)(getRepositoryFileUrl_1.GET_REPOSITORY_FILE_URL, {
        variables: { groupId },
        skip: !groupId,
    });
    return {
        repositoryFileUrl: (data === null || data === void 0 ? void 0 : data.getRepositoryFileUrl) || null,
        loading,
        error,
        refetch,
    };
};
exports.useGetRepositoryFileUrl = useGetRepositoryFileUrl;
