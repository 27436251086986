"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSyncRepositoryAccessByCheckoutId = void 0;
const client_1 = require("@apollo/client");
const syncRepositoryAccessByCheckoutId_1 = require("../mutations/syncRepositoryAccessByCheckoutId");
const useSyncRepositoryAccessByCheckoutId = () => {
    const [syncRepositoryAccessByCheckoutId, { data, loading, error }] = (0, client_1.useMutation)(syncRepositoryAccessByCheckoutId_1.SYNC_REPOSITORY_ACCESS_BY_CHECKOUT_ID);
    return {
        syncRepositoryAccessByCheckoutId,
        data: data === null || data === void 0 ? void 0 : data.syncRepositoryAccessByCheckoutId,
        loading,
        error,
    };
};
exports.useSyncRepositoryAccessByCheckoutId = useSyncRepositoryAccessByCheckoutId;
