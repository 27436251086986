"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_GROUP_SETTINGS = void 0;
const client_1 = require("@apollo/client");
exports.UPDATE_GROUP_SETTINGS = (0, client_1.gql) `
  mutation UpdateGroupSettings($groupId: String!, $data: JSONObject!) {
    updateGroupSettings(groupId: $groupId, data: $data) {
      id
      startAt
      endAt
      availabilityEndDate
      allowAutoAccess
      allowDisplayCalendar
      allowDisplaySeats
      allowNewEntries
      allowProgramPreview
      allowTryForFree
      mentorCalendarUrl
      previewGroupId
      viewInMarketplace
      hasRepository
      repositoryPrice
      repositoryFilePath
    }
  }
`;
