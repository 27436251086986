"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialPageData = exports.initialGroup = void 0;
const constants_1 = require("../organisation/constants");
const graphql_1 = require("../types/graphql");
const constants_2 = require("../user/constants");
exports.initialGroup = {
    paymentMode: graphql_1.PaymentMode.PAYMENT,
    updatedAt: "",
    id: "",
    name: "",
    mentorId: "",
    organisationId: "",
    currency: graphql_1.Currency.EUR,
    tags: [],
    pricePerSeat: 1,
    state: graphql_1.GroupState.inactive,
    program: {},
    mentor: constants_2.initialUserValues,
    organisation: constants_1.initialOrg,
    activatedAt: "",
    createdAt: "",
    accesses: [],
    settings: {
        id: "",
        groupId: "",
        maxSeats: 0,
        previewGroupId: "",
        mentorCalendarUrl: "",
        isPrimaryGroup: false,
        allowDisplayCalendar: false,
        allowTryForFree: false,
        allowAutoAccess: false,
        allowNewEntries: false,
        allowProgramPreview: false,
        allowDisplaySeats: false,
        hasRepository: false,
        repositoryPrice: 0,
        repositoryFilePath: "",
        viewInMarketplace: false,
    },
};
exports.initialPageData = {
    benefits: [],
    coverImageSrc: "",
    currency: graphql_1.Currency.EUR,
    paymentMode: graphql_1.PaymentMode.PAYMENT,
    coverType: graphql_1.MediaType.IMAGE,
    programId: "",
    notionPageMap: null,
    tags: [],
    notionPageId: null,
    termsAndConditionsUrl: "",
    gtmId: "",
    privacyPolicyUrl: "",
    showPrice: false,
    ctaButtonType: "",
    contactListId: null,
    document: {
        blocks: [],
    },
    coverMediaSrc: "",
    documentId: "",
    groupId: "",
    groupName: "",
    groupState: graphql_1.GroupState.inactive,
    id: "",
    maxSeats: 0,
    pricePerSeat: 0,
    shortDsc: "",
    slug: "",
    state: graphql_1.PublishState.DRAFT,
    allowAutoAccess: false,
    allowNewEntries: false,
    allowTryForFree: false,
    allowDisplayCalendar: false,
    mentorCalendarUrl: "",
    previewGroupId: "",
    allowProgramPreview: false,
    allowDisplaySeats: false,
    usersAmount: 0,
};
