"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCheckIfUserHasRepositoryAccess = void 0;
const client_1 = require("@apollo/client");
const checkIfUserHasRepositoryAccess_1 = require("../queries/checkIfUserHasRepositoryAccess");
const useCheckIfUserHasRepositoryAccess = (groupId) => {
    const { data, loading, error, refetch } = (0, client_1.useQuery)(checkIfUserHasRepositoryAccess_1.CHECK_IF_USER_HAS_REPOSITORY_ACCESS, {
        variables: { groupId },
        skip: !groupId,
    });
    return {
        hasRepositoryAccess: (data === null || data === void 0 ? void 0 : data.checkIfUserHasRepositoryAccess) || false,
        loading,
        error,
        refetch,
    };
};
exports.useCheckIfUserHasRepositoryAccess = useCheckIfUserHasRepositoryAccess;
