"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitiateRepositoryPurchase = void 0;
const client_1 = require("@apollo/client");
const initiateRepositoryPurchase_1 = require("../mutations/initiateRepositoryPurchase");
const useInitiateRepositoryPurchase = () => {
    const [initiateRepositoryPurchase, { data, loading, error }] = (0, client_1.useMutation)(initiateRepositoryPurchase_1.INITIATE_REPOSITORY_PURCHASE);
    return {
        initiateRepositoryPurchase,
        data: data === null || data === void 0 ? void 0 : data.initiateRepositoryPurchase,
        loading,
        error,
    };
};
exports.useInitiateRepositoryPurchase = useInitiateRepositoryPurchase;
