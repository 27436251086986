"use strict";
"use client";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitGroupAccessPayment = void 0;
const client_1 = require("@apollo/client");
const initiateGroupAccessPayment_1 = require("../mutations/initiateGroupAccessPayment");
const useInitGroupAccessPayment = () => {
    const [init, { loading, called }] = (0, client_1.useMutation)(initiateGroupAccessPayment_1.INITIATE_GROUP_ACCESS_PAYMENT);
    const initAccessPayment = (groupId, email, checkoutParams, includeRepository) => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield init({
            variables: { groupId, email, checkoutParams, includeRepository },
        });
        return data === null || data === void 0 ? void 0 : data.initiateGroupAccessPayment;
    });
    return { initAccessPayment, loading, called };
};
exports.useInitGroupAccessPayment = useInitGroupAccessPayment;
