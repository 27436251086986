"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetStatistics = void 0;
const client_1 = require("@apollo/client");
const getStatistics_1 = require("../queries/getStatistics");
const useGetStatistics = () => {
    const { data, loading, error } = (0, client_1.useQuery)(getStatistics_1.GET_STATISTICS);
    return {
        statistics: data === null || data === void 0 ? void 0 : data.getStatistics,
        isLoading: loading,
        error,
    };
};
exports.useGetStatistics = useGetStatistics;
